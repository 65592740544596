// extracted by mini-css-extract-plugin
export var ArtistDescription = "Delta2-module--ArtistDescription--4vwU3";
export var ArtistInfos = "Delta2-module--ArtistInfos--dn7DI";
export var ButtonWrapper = "Delta2-module--ButtonWrapper --u7LBX";
export var CardWrapper = "Delta2-module--CardWrapper--tqZgr";
export var CarrouselWrapper2 = "Delta2-module--CarrouselWrapper2--Z19Ru";
export var Citations = "Delta2-module--Citations--t+xLN";
export var DescriptionWrapper = "Delta2-module--DescriptionWrapper--wXeWP";
export var ImageWrapper = "Delta2-module--ImageWrapper--M3+Oc";
export var LinkWrapper = "Delta2-module--LinkWrapper--LjLRd";
export var MobileProtrait = "Delta2-module--MobileProtrait--5l6Y0";
export var More = "Delta2-module--More--yV71h";
export var MoreButton = "Delta2-module--MoreButton--oKGdQ";
export var NameWrapper = "Delta2-module--NameWrapper--EtwkG";
export var PdpWrapper = "Delta2-module--PdpWrapper--iBZXy";
export var PhotosWrapper = "Delta2-module--PhotosWrapper--eoit0";
export var ProfilWrapper = "Delta2-module--ProfilWrapper--QLlSE";
export var TitleWrapper = "Delta2-module--TitleWrapper--3Viik";
export var Wrapper = "Delta2-module--Wrapper--Xtig8";